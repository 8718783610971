<template>
  <div>
    <nav-bar class="nav" v-if="showBackBtn" @onBack="handleBack" title="返回"/>
    <div class="feedbackArea">
      <div class="title">异常反馈</div>
      <div class="uploadArea">
        <div class="headInfo"> 请上传截图/视频: </div>
        <image-collection
          ref="situationImg"
          :accept="accept"
          :uploadOptions="uploadOptions"
          :isShowAddBtn="true"
          uploadText=""
          btn-size="plus"
        />
      </div>
      <div class="situationArea">
        <div class="headInfo"> 请选择您遇到的情况：</div>
        <div
          v-for="item in situationList"
          :key="item"
          :class="['situationItem', {'clickedItem': isSelect(item)} ]"
          @click="clickItem(item)"
        >
          <div class="checkItem">
            <span class="detail">{{ item }}</span>
            <van-icon v-if="isSelect(item)" name="success"/>
          </div>
          <textarea 
            v-if="isOthers && item === FEEDBACK_OTHERS" 
            v-model="inputValue" 
            class="others" 
            type="text" 
            maxlength="100"
            @click="($event)=> $event.stopPropagation()"/>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-button 
        class="feedbackSubmit" 
        type="primary"
        :loading="loading"
        loading-text="提交中..."
        @click="handleSubmitFeedback"
      > 
        提交反馈
      </van-button>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/nav-bar.vue";
import imageCollection from "../ImageCollection/Index";
import { getRiskTypesList, submitFeedback } from '@/apis';
import { ACCEPT_IMAGE, ACCEPT_VIDEO, FEEDBACK_OTHERS } from '@/constant';
import backListMixins from '../mixins/back-list'
import submitFileMixins from '../mixins/submit-file'
import { checkUploadImages } from '../shared'
import { Toast, Dialog } from 'vant'
import noop from 'lodash/noop'
import { getCookie } from "@/tools/cookie";

export default {
    name: 'abnormalFeedback',
    components: {
      imageCollection,
      NavBar,
    },
    mixins:[ backListMixins, submitFileMixins ],
    data() {
      return {
        loading: false,
        urlParams: {},
        selectItems: [],
        situationList: [],
        accept: [...ACCEPT_IMAGE, ...ACCEPT_VIDEO].join(','),
        FEEDBACK_OTHERS,
        isOthers: false,
        inputValue: '',
        uploadOptions: null,
      }
    },
    methods: {
      isSelect(item) {
        return this.selectItems.includes(item) || (this.isOthers && item === FEEDBACK_OTHERS)
      },

      async getJobDetails() {
        try {
          const { dataType } = this.urlParams
          const res = await getRiskTypesList({dataType})
          if(res && res.code === 0) {
            this.situationList = res.data?.riskControllingType || []
          } else {
            Toast.fail(res?.msg ?? '获取异常列表失败！')
          }
        } catch(err) {
          Toast.fail(err?.msg ?? '获取异常列表失败！')
        } finally {
          this.situationList.push(FEEDBACK_OTHERS)
        }
      },

      initClass() {
        const situationItems = document.getElementsByClassName('situationItem');
        const otherSituation = situationItems[situationItems.length - 1];
        const title = document.getElementsByClassName('headInfo');
        // 清除类
        otherSituation.classList.remove('unCheckOthers');
        title[0].classList.remove('unCheckTitle');
        title[1].classList.remove('unCheckTitle');
      },

      async handleSubmitFeedback() {
        if(this.loading) return ;
        if(this.isOthers && this.inputValue){
          this.selectItems.push(this.inputValue)
        }

        const imgs = this.$refs.situationImg.getImagesList()
        const isOthersValue = this.isOthers && !this.inputValue

        // 验错情况
        if(this.selectItems.length === 0 || imgs.length < 1 || isOthersValue) {
          const situationItems = document.getElementsByClassName('situationItem');
          const otherSituation = situationItems[situationItems.length - 1];
          const title = document.getElementsByClassName('headInfo');
          //初始化效果
          this.initClass();
          // 命中校验则添加类
          if(isOthersValue) {
            otherSituation.classList.add('unCheckOthers');
          }
          if(imgs.length < 1) {
            title[0].classList.add('unCheckTitle');
          }
          if(this.selectItems.length === 0) {
            title[1].classList.add('unCheckTitle');
          }
         return;
        }
        this.loading = true;

        // 提交信息
        try {
          const submitInfo = await checkUploadImages(imgs)
          if(submitInfo) {
            Dialog.confirm({
              message: '确认提交？',
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
            .then(() => {
              this.handleSubmit(submitInfo.picUrlList); 
            })
            .catch(noop);
          }
        } catch (e) {
          Toast.fail(e?.message ?? '提交异常！')
        } finally {
          this.loading = false
        }
      },

      async handleSubmit(fileUrls) {
        const { dataType, userTaskId } = this.urlParams
        try {
          const res = await submitFeedback({
            dataType, 
            userTaskId,
            riskControllingTypes: this.selectItems,
            channelCode: getCookie("channelCode"),
            fileUrls,
          })
          if(res && res.code === 0) {
            Dialog.alert({
              message: '提交成功'
            })
            this.selectItems = [];
            this.$refs.situationImg.clearImages();
            this.initClass();
          } else {
            Toast.fail(res?.msg ?? '提交反馈失败！')           
          }
        } catch(e) {
          Toast.fail(e?.msg ?? '提交反馈失败！')
        }   
      },

      clickItem(item) {
        // 若为其他情况
        if(item === FEEDBACK_OTHERS){
          this.isOthers = !this.isOthers
          return
        }
        let arrIndex = this.selectItems.indexOf(item);
        if(arrIndex > -1) {
          this.selectItems.splice(arrIndex, 1);
        } else {
          this.selectItems.push(item)
        }
      },
    },
}
</script>

<style lang="less" scoped>
.nav {
  position:fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.headInfo {
  font-weight: 500;
  margin-bottom: 10px;
}
.feedbackArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  margin: 60px 20px;
  .title {
    font-size: 20px;
  }
  .uploadArea{
    width: 95%;
    margin-top: 20px;
  }
  .situationArea {
    width: 95%;
    margin-top: 10px;
    .situationItem {
      border: 1px solid #e7e7e7;
      margin: 10px 0;
      padding: 8px 20px;
      .checkItem {
        display: flex;
        justify-content: space-between;
        .detail {
          width: 95%;
          word-break: break-all;
        }
      }
      .others{
        display: block;
        width: 100%;
        height: 110px;
        margin-top: 5px;
        border-color: #ddd9d9;
      }
    }
    .clickedItem {
      border: 1px solid #3e7bf3;
      color: #3e7bf3;
    }
  }
}

.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  background: white;
  .feedbackSubmit {
    width: 95%;
    color: #fff;
    background-color: #007aff;
    border-radius: 16px;
    margin-bottom: 10px;
  }
}
.unCheckTitle {
  color:#e93323;
}
.unCheckOthers {
  border: 1px solid #e93323 !important;
}
</style>